<template>
  <v-container fluid fill-height >
  <v-card
  class="d-flex flex-column mx-auto"
  width="80%"
  >

  <v-card-title>
    <div class="pageTitle">
      Upload Audio
    </div>
  </v-card-title>



  <v-card-text>
            <input type="file" ref="fileInput" @change="onFileSelected" />
        </v-card-text>
        <v-dialog
          v-model="loadingD"
          hide-overlay
          persistent
          width="80%"
          id="loadingDiv"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              {{loadText}}
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card-title>
          <div class="pageTitle">
            Results
          </div>
        </v-card-title>

        <v-card-text>
                  {{results}}
              </v-card-text>

          </v-card>
  </v-container>
</template>

<script>

import { AudioContext } from 'standardized-audio-context'
const toWav = require('audiobuffer-to-wav')

export default {
  data() {
    return {
      loadingD: false,
      loadText: 'Transcribing Audio',
      apiKey: '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',
      model: 'whisper-1',
      audioBuffer: null,
      audioSrc: null,
      selectedFile: null,
      results: ''
    }
  },
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
      this.results = ''
      this.convertOGG()
      //this.transcribeAudio()
    },
    async convertOGG() {
      const fileReader = new FileReader()

      fileReader.onload = async () => {
        const audioContext = new AudioContext()
        const audioData = await audioContext.decodeAudioData(fileReader.result)
        // Store the AudioBuffer in the component data
        this.audioBuffer = audioData
        // Create a WAV blob from the AudioBuffer
        const wavBlob = new Blob([toWav(audioData)], { type: 'audio/wav' })

        // Create a URL for the WAV blob
        // const wavUrl = URL.createObjectURL(wavBlob)

        this.audioSrc  = new File([wavBlob], 'audio.wav', {
          type: "audio/wav", // this should be from your string split of the filename to check the file upload type.
          lastModified: new Date(),
        });
        this.transcribeAudio()
      }

      fileReader.readAsArrayBuffer(this.selectedFile)
    },
    async transcribeAudio() {
      this.loadingD = true
      const formData = new FormData()
      formData.append('file', this.audioSrc)
      formData.append('model', this.model)

      const headers = new Headers()
      headers.append('Authorization', `Bearer ${this.apiKey}`)

      const config = {
        method: 'POST',
        body: formData,
        headers: headers
      }

      try {
        const response = await fetch(
          'https://api.openai.com/v1/audio/transcriptions',
          config
        )
        const data = await response.json()
        //console.log(data)
        this.loadingD = false
        this.results = data.text
      } catch (error) {
        this.loadingD = false
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>

.expText {
  font-size: 17px;
  color: #000000;
}

</style>

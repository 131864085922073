import Vue from 'vue'
import VueRouter from 'vue-router'

import AudioToText from '../components/AudioToText.vue'
import ShareComp from '../components/ShareComp.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: AudioToText },
  { path: '/shareComp', component: ShareComp }
]

const router = new VueRouter({
  routes
})

export default router
